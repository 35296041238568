<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_language')}}</b-breadcrumb-item>
            </div>
        </div>


        <b-card
                no-body
                class="mb-0"
        >
            <b-card-header class="pb-50">
                <h5>{{ $t('label_language') }}</h5>
            </b-card-header>
            <div class="m-2">

                <b-row>

                    <b-col
                            cols="12"
                            md="5"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"

                    >

                        <v-select
                                v-model="tableData.perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                :searchable="false"
                                class="per-page-selector d-inline-block mx-50"
                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>
                    </b-col>

                    <b-col
                            cols="12"
                            md="5"
                            class="ml-auto d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >

                        <b-form-input
                                v-model="tableData.searchQuery"
                                type="search"
                                debounce="500"
                                class="d-inline-block mr-1 "
                                :placeholder="$t('label_search')+'...'"
                        />
                        <b-button class="" v-if="$can('edit', MODULE_PREFIX)"
                                  variant="primary"
                                  @click="editedItem = false; $bvModal.show(MODULE_PREFIX + '-modal')"
                        >
                            <span class="text-nowrap">{{ $t('label_add_new') }}</span>
                        </b-button>

                    </b-col>
                </b-row>

            </div>

            <!--***********************************************************TABLE*********************************************-->

            <div class="px-2 table-container-wrap">
                <!--sticky-header-->
                <b-table
                        ref="table"
                        class="position-relative transited-table"
                        :busy="isBusy"
                        no-border-collapse
                        :items="tableItems"
                        responsive
                        :fields="columnsDefs"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                        @row-clicked="rowClicked"

                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>


                    <!--<template #cell(id)="data">-->
                        <!--{{'#' + data.item.id}}-->
                    <!--</template>-->

                    <template v-for="slotName in langSlots"  v-slot:[slotName]="data">
                        {{ data.item.trans[data.field.key]}}
                        <!--{{data}}-->
                    </template>


                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>

                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >

                            <b-pagination
                                    v-model="tableData.currentPage"
                                    :total-rows="tableTotal"
                                    :per-page="tableData.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>


        </b-card>

        <process-item-modal
                :languages="appConfig.app.languages"
                @hideModal="chosenRowEl? chosenRowEl.closest('tr').classList.remove('row-selected') : false; chosenRowEl = false"
                :edited-item="editedItem"
                @item-added="refreshDataTable()"
                @item-edited="refreshDataTable()"
        ></process-item-modal>

    </div>
</template>

<script>
    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import { $themeConfig as appConfig } from '@themeConfig'

    import processItemModal from './modals/processItemModal'

    import {MODULE_PREFIX} from './moduleHelper'

    export default {
        components: {
            BCard,

            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,
            BCardHeader,
            BTooltip,
            vSelect,
            processItemModal
        },

        // props: ['tableType'],

        data() {
            return {
                MODULE_PREFIX,

                appConfig,

                columnsDefs: [

                    {label: 'label_label_key', key: 'label_key', sortable: false},
                ],

                editedItem: false,

                isBusy: false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [ 50, 100, 200],
                },

                langSlots:[],
                chosenRowEl: false
            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function () {
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },

        },

        created() {
            for(let i = 0; i<this.appConfig.app.languages.length; i++) {
                this.langSlots.push('cell('+this.appConfig.app.languages[i]+')');
                this.columnsDefs.push({label: this.appConfig.app.languages[i], key: this.appConfig.app.languages[i], sortable: false});
            }
// console.log(this.columnsDefs);
            this.refreshDataTable(true);
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {

            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/' + this.MODULE_PREFIX , {
                    params: {

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc ? true : null,

                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                    this.$store.dispatch('app/UPDATE_LOCALE', {locale:this.$locale, force: true});
                });
            },

            rowClicked(item, insex, event){
                if(!this.$can('edit', this.MODULE_PREFIX)){
                    return false;
                }
                this.editedItem = item;
                this.chosenRowEl = event.target;
                this.chosenRowEl.closest('tr').classList.add('row-selected');
                this.$bvModal.show(this.MODULE_PREFIX + '-modal');
            },

        },


    }
</script>

